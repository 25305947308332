import "./index.css";

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import React from "react";
import ReactDOM from "react-dom/client";
import Atmosphere from "./components/Atmosphere/Atmosphere";
import { DataContextProvider } from "./components/contexts/DataContext";
import BlogPost from "./components/Post/BlogPost";
import PortfolioPost from "./components/Post/PortfolioPost";
import Wrapper from "./components/Wrapper";
import WrapperChild from "./components/WrapperChild";
import Artwork from "./pages/Artwork";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Github from "./pages/Github";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Resume from "./pages/Resume";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Wrapper />}>
      <Route
        path="test"
        element={
          <div className="bg-midnightBlue text-white w-full h-full absolute">
            <Atmosphere />
          </div>
        }
      ></Route>
      <Route index={true} element={<Home />} />
      <Route
        path="blog"
        element={
          <WrapperChild>
            <Blog />
          </WrapperChild>
        }
      >
        <Route path=":postId" element={<BlogPost />} />
      </Route>
      <Route
        path="portfolio"
        element={
          <WrapperChild>
            <Portfolio />
          </WrapperChild>
        }
      >
        <Route path=":postId" element={<PortfolioPost />} />
      </Route>
      <Route path="resume" element={<Resume />}></Route>
      <Route path="artwork" element={<Artwork />}></Route>
      <Route path="github" element={<Github />}></Route>
      <Route path="contact" element={<Contact />}></Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <DataContextProvider>
      <RouterProvider router={router} />
    </DataContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
