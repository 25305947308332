import { Outlet, useOutlet } from "react-router-dom";

import { PropsWithChildren } from "react";

const WrapperChild = ({ children }: PropsWithChildren) => {
  const outlet = useOutlet();

  return (
    <div className="flex flex-col md:flex-row h-full w-full md:overflow-hidden">
      <div
        className={`transition-all duration-1000 h-[50vh] shadow-lg overflow-scroll my-10 md:my-0 p-5 md:p-0 md:overflow-hidden md:h-full ${
          outlet ? "md:w-1/3" : "w-full"
        }`}
      >
        {children}
      </div>
      <div
        className={`transition-all duration-1000 h-screen overflow-auto overflow-x-hidden ${
          outlet ? "md:w-2/3" : "w-0"
        }`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default WrapperChild;
