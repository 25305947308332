import Typography from "../shared/Typography/Typography";

function Github() {
  return (
    <div className="p-6 h-full overflow-auto">
      <Typography name="display1">Github</Typography>
    </div>
  );
}

export default Github;
