import { useLocation, useMatches, useNavigate } from "react-router-dom";

import { useEffect } from "react";
import BackButton from "../components/BackButton";
import Blogroll from "../components/Blogroll/Blogroll";
import { useDataContext } from "../components/contexts/DataContext";
import Typography from "../shared/Typography/Typography";

function Blog() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { blogPosts } = useDataContext();
  const matches = useMatches();
  const isSecondLevel = matches.length === 3;

  const autoDirect = () => {
    setTimeout(() => {
      if ((!state || state.from === "sidebar") && blogPosts) {
        blogPosts && navigate(`/blog/${blogPosts[0].id}`);
      }
    }, 1000);
  };

  useEffect(() => {
    autoDirect();
  }, []);

  return (
    <div className="p-6 h-full overflow-auto">
      <div className="flex">
        <Typography name="display1">Blog</Typography>
        {isSecondLevel && (
          <div className="ml-auto">
            <BackButton path="/blog" />
          </div>
        )}
      </div>

      <Blogroll
        posts={blogPosts}
        basePath="blog"
        // className="max-w-[40vw]"
        showImages={true}
      />
    </div>
  );
}

export default Blog;
