import { useMatches } from "react-router-dom";
import BackButton from "../components/BackButton";
import { useDataContext } from "../components/contexts/DataContext";
import PortfolioRoll from "../components/PortfolioRoll/PortfolioRoll";
import Typography from "../shared/Typography/Typography";

const Portfolio = () => {
  const { portfolioItems } = useDataContext();
  const matches = useMatches();
  const isSecondLevel = matches.length === 3;

  return (
    <div className="p-6 h-full overflow-auto">
      <div className="flex gap-2 items-center">
        <Typography name="display1">Portfolio</Typography>
        {isSecondLevel && (
          <div className="ml-auto">
            <BackButton path="/portfolio" />
          </div>
        )}
      </div>

      <PortfolioRoll posts={portfolioItems} showImages basePath="portfolio" />
    </div>
  );
};

export default Portfolio;
