import Typography from "../shared/Typography/Typography";

function Contact() {
  return (
    <div className="p-6 h-full overflow-auto">
      <Typography name="display1">Contact</Typography>
    </div>
  );
}

export default Contact;
