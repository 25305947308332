import { formatDate } from "date-fns";
import { useParams } from "react-router-dom";
import { TContentItem } from "../../constants/types";
import TabArrow from "../../shared/Button/TabArrow/TabArrow";
import Typography from "../../shared/Typography/Typography";

const Post = ({ posts }: { posts: TContentItem[] }) => {
  const { postId } = useParams();

  const postIndex = posts.findIndex((p) => `${p.id}` === `${postId}`);

  if (postIndex === undefined) return null;

  const post = posts[postIndex];
  const nextPost = posts[postIndex + 1];
  const prevPost = posts[postIndex - 1];

  return (
    <div className="mb-10 p-10 md:p-20 relative bg-blue bg-opacity-10 transition-all">
      <div className="mb-10 relative flex w-full transition-all">
        <div className="w-1/3">
          {prevPost && (
            <TabArrow
              title={prevPost.title}
              path={`/blog/${prevPost.id}`}
              direction="left"
            />
          )}
        </div>
        <div className="ml-auto w-1/3">
          {nextPost && (
            <TabArrow
              title={nextPost.title}
              path={`/blog/${nextPost.id}`}
              direction="right"
            />
          )}
        </div>
      </div>
      <Typography name="heading1" className="mb-10">
        {post?.title}
      </Typography>
      <Typography name="subheading1">
        {post?.date && formatDate(post?.date, "yyyy.MM.dd")}
      </Typography>
      {post?.content && (
        <div
          className="post mt-4 text-md text-secondaryText font-thin leading-1"
          dangerouslySetInnerHTML={{
            __html: post?.content,
          }}
        ></div>
      )}
      <div className="text-center font-special mt-40">- - -</div>
    </div>
  );
};

export default Post;
