import { Outlet, useLocation, useMatches } from "react-router-dom";

import { PropsWithChildren } from "react";
import Atmosphere from "./Atmosphere/Atmosphere";
import Header from "./Header";
import SeparatorLine from "./SeparatorLine";

const Wrapper = ({ children }: PropsWithChildren) => {
  const matches = useMatches();
  const { pathname } = useLocation();
  const isHome = pathname === "/";
  const isFirstLevel = matches.length === 2;
  const isSecondLevel = matches.length === 3;
  return (
    <div className="text-primaryText bg-midnightBlue">
      <Atmosphere />
      <div
        className={`relative z-20 font-body h-screen flex flex-col md:flex-row`}
      >
        <div
          className={`transition-all duration-1000 relative w-full ${
            isHome
              ? "md:w-1/3"
              : isFirstLevel
              ? "md:w-1/4"
              : isSecondLevel
              ? "md:w-1/5"
              : "w-full"
          }`}
        >
          <Header />
          <SeparatorLine />
        </div>
        <div
          className={`transition-all duration-1000 w-full ${
            isFirstLevel ? "md:w-3/4" : isSecondLevel ? "md:w-4/5" : "w-full"
          }`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
