const endpoints = {
  pages: process.env.REACT_APP_WP_PAGES_URL,
  media: process.env.REACT_APP_WP_MEDIA_URL,
  posts: process.env.REACT_APP_WP_POSTS_URL,
};

export const getWordpressUrl = (
  endpoint: "pages" | "media" | "posts",
  parameters?: {
    parent?: number;
    per_page?: number;
  }
) => {
  return `${endpoints[endpoint]}${
    parameters
      ? "?" +
        Object.entries(parameters)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")
      : ""
  }`;
};
