import { Link } from "react-router-dom";

const TabArrow = ({
  path = "/",
  title,
  direction = "left",
}: {
  path: string;
  title: string;
  direction?: "left" | "right";
}) => {
  return (
    <Link
      to={path}
      className={`flex gap-4 items-center group ${
        direction === "right" ? "justify-end" : ""
      }`}
    >
      <div className={`${direction === "left" ? "order-1" : "order-2"}`}>
        <TabIcon direction={direction} />
      </div>
      <div
        className={`${
          direction === "left" ? "order-2" : "order-1 text-right"
        } text-xs font-display text-white font-medium transition-all opacity-0 group-hover:opacity-100`}
      >
        {title}
      </div>
    </Link>
  );
};

export const TabIcon = ({ direction }: { direction?: "left" | "right" }) => {
  return (
    <div className="relative flex-none w-6 h-6">
      <div
        className={`absolute top-1 ${
          direction === "right" ? "-right-2" : "left-0"
        } w-line h-full bg-white group-hover:bg-green`}
      ></div>
      <div
        className={`absolute ${
          direction === "right" ? "-right-1" : "left-1"
        } h-line w-full bg-white group-hover:bg-green`}
      ></div>
    </div>
  );
};

export default TabArrow;
