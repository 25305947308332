import { memo } from "react";

const Point = memo(
  ({
    left = 0,
    top = 0,
    text,
    index,
  }: {
    left: number;
    top: number;
    text?: string;
    index: number;
  }) => {
    return (
      <div
        style={{ left, top, opacity: index * 0.1 }}
        className="absolute z-20 transition-all duration-1000 text-xs"
      >
        <div className="bg-white animate-pulser relative z-20 h-1 w-1 hover:bg-darkBlue hover:w-10 hover:h-10"></div>
        {text}
      </div>
    );
  }
);

export default Point;
