import Typography from "../shared/Typography/Typography";

function Artwork() {
  return (
    <div className="p-6 h-full overflow-auto">
      <Typography name="display1">Artwork</Typography>
    </div>
  );
}

export default Artwork;
