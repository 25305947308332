import { Dispatch, SetStateAction } from "react";
import { Link, useLocation } from "react-router-dom";

import { TNavLink } from "../../constants/types";

function NavSlider({
  links,
  setHover,
  hover,
}: {
  links: TNavLink[];
  setHover: Dispatch<SetStateAction<any>>;
  hover: number;
}) {
  const { state, pathname } = useLocation();
  const isHome = pathname === "/";

  return (
    <div className="flex flex-col mt-4 relative">
      <div
        className={`absolute h-14 bg-gradient-to-r from-green w-full opacity-50 transition-all ${
          state?.hoverId > 0 ? "visible" : "hidden"
        }`}
        style={{
          top:
            state?.hoverId && state?.hoverId === 1
              ? 0
              : state?.hoverId
              ? 60 * (state?.hoverId - 1)
              : 0,
        }}
      ></div>
      {links?.map((link, i) => (
        <Link
          key={link.path}
          to={link.path}
          target={link?.externalLink ? "new_window" : "_self"}
          state={{ hoverId: hover, from: "sidebar" }}
          className="relative z-10"
          onMouseOver={() => setHover(i + 1)}
        >
          <button
            className={`${isHome ? "px-20" : "px-10"} ${
              pathname.includes(link.path) ? "font-bold" : ""
            } py-4 text-xl font-thin w-full text-left hover:text-gray hover:bg-darkBlue hover:bg-opacity-70 active:bg-gradient-to-r from-darkBlue to-blue transition-all duration-500`}
          >
            {link.title}
          </button>
        </Link>
      ))}
    </div>
  );
}

export default NavSlider;
