import me from "../img/me.jpg";
import { TabIcon } from "../shared/Button/TabArrow/TabArrow";

function Home() {
  // const { blogPosts } = useDataContext();

  return (
    <div className="h-full w-full p-16 md:p-20 overflow-auto ">
      <div className="flex flex-col md:flex-row items-center relative mt-[25vh]">
        <div className="font-thin mr-2 text-secondaryText p-4 md:w-[60%] md:text-xl">
          <div className="absolute -top-10 -left-5">
            <TabIcon />
          </div>
          <p>
            <span className="font-special font-normal text-white text-3xl md:text-4xl mr-1">
              Hello I'm Eric,
            </span>{" "}
            currently Senior UI Engineer at{" "}
            <a href="https://astranis.com">Astranis Space Technologies</a>.
          </p>
          <p>
            I've worn many hats as a visual designer in branding, graphics, web,
            mobile, and 3D animation. For 8 years I've led in user interface and
            user experience development and front-end engineering.
          </p>
          <p>
            I do a lot of things with{" "}
            <a href="https://efwjames.com">visual media</a>, let's{" "}
            <a href="https://x.com/efwjames">keep in touch!</a>
          </p>

          <p className="mt-10 font-special text-xl uppercase flex flex-col text-center md:flex-row gap-8">
            <a href="https://www.youtube.com/@ericjam">YouTube</a>
            <a href="https://www.instagram.com/eric_james_studio">Instagram</a>
            <a href="https://efwjames.com">Artwork</a>
            <a href="https://github.com/ericjames">Github</a>
          </p>
        </div>
        <div className="border border-blue p-2 hover:border-4 w-40 transition-all">
          <a href="https://www.linkedin.com/in/ericfwjames/" target="new">
            <img
              src={me}
              className="w-40 -mt-5 hover:brightness-150 hover:-mt-10 transition-all"
            />
          </a>
        </div>
      </div>

      {/* <Typography name="display1">Blog</Typography>
      <Blogroll posts={blogPosts?.slice(0, 5)} basePath="blog" />
      <Button>
        <Link to="/blog">More Posts</Link>
      </Button> */}
    </div>
  );
}

export default Home;
