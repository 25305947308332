import { PropsWithChildren, ReactNode, memo } from "react";

const getHeading = (
  name: string,
  children: ReactNode | undefined | string,
  className?: string
) => {
  const headings: {
    [key: string]: JSX.Element;
  } = {
    display1: (
      <h1 className={`text-3xl font-special uppercase ${className}`}>
        {children}
      </h1>
    ),
    heading1: (
      <h1 className={`text-4xl font-thin font-display ${className}`}>
        {children}
      </h1>
    ),
    heading2: (
      <h2 className={`text-xl font-display ${className}`}>{children}</h2>
    ),
    heading3: (
      <h3 className={`text-sm font-display ${className}`}>{children}</h3>
    ),
    subheading1: (
      <div className="text-secondaryText font-display my-4">{children}</div>
    ),
  };
  return headings[name] || <>{children}</>;
};

const Typography = memo(
  ({
    name,
    children,
    className,
  }: PropsWithChildren<{ name: string; className?: string }>) => {
    return getHeading(name, children, className);
  }
);

export default Typography;
