import { TNavLink } from "./types";

export const navLinks: TNavLink[] = [
  {
    path: "/blog",
    title: "Blog",
  },
  {
    path: "/portfolio",
    title: "Portfolio",
  },
  // {
  //   path: "/resume",
  //   title: "Resume",
  // },
  // {
  //   path: "https://efwjames.com/",
  //   externalLink: true,
  //   title: "Artwork",
  // },
  // {
  //   path: "https://instagram.com/eric_james_studio",
  //   externalLink: true,
  //   title: "Instagram",
  // },
  // {
  //   path: "https://youtube.com/@ericjam",
  //   externalLink: true,
  //   title: "YouTube",
  // },
  // {
  //   path: "https://github.com/ericjames",
  //   externalLink: true,
  //   title: "Github",
  // },
];
