import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { getBlogPosts, getPortfolioPages } from "./utils";

import { TContentItem } from "../../constants/types";

type DataContextType = {
  portfolioItems: TContentItem[] | undefined;
  blogPosts: TContentItem[] | undefined;
};

const defaultValues = {
  portfolioItems: undefined,
  blogPosts: undefined,
};

const DataContext = createContext<DataContextType>(defaultValues);

export const DataContextProvider = ({ children }: PropsWithChildren) => {
  const [portfolioItems, setPortfolio] = useState<TContentItem[] | undefined>();
  const [blogPosts, setBlogPosts] = useState<TContentItem[] | undefined>();

  useEffect(() => {
    getPortfolioPages({
      parent: 1082,
      orderby: "menu_order",
      order: "asc",
    }).then((pages) => {
      setPortfolio(pages);
    });
    getBlogPosts({
      per_page: 5,
    }).then((posts) => {
      // console.log(posts);
      setBlogPosts(posts);
    });
  }, []);
  return (
    <DataContext.Provider value={{ portfolioItems, blogPosts }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
