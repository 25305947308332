import { memo, useMemo } from "react";
import { T_Dot, T_Frame, T_Window } from "./Atmosphere";

import Point from "./Point";

const getPosition = (
  index: number,
  widthCount: number,
  heightCount: number,
  width: number,
  height: number,
  visualMode: T_Frame
): T_Dot => {
  const row = Math.floor(index / widthCount);
  const col = Math.round(index % widthCount);
  const left = Math.round(width * index);
  const top = Math.round(height * index);

  if (visualMode === "diagonal") {
    return [left, top];
  } else if (visualMode === "grid") {
    return [col * width, row * height];
  }
  return [0, top];
};

const Canvas = memo(
  ({
    widthCount = 10,
    heightCount = 10,
    windowSize,
    visualMode,
    startingLeft,
    startingTop,
  }: {
    windowSize: T_Window;
    widthCount?: number;
    heightCount?: number;
    visualMode?: T_Frame;
    startingLeft?: number;
    startingTop?: number;
  }) => {
    const dots: T_Dot[] = useMemo(() => {
      const left = startingLeft || 0;
      const top = startingTop || 0;
      const dot = [left, top];
      const width = Math.round((windowSize.width + 100) / widthCount);
      const height = Math.round((windowSize.height + 300) / heightCount);

      return Array(widthCount * heightCount)
        .fill(dot)
        .map((_, index) => {
          return getPosition(
            index,
            widthCount,
            heightCount,
            width,
            height,
            visualMode
          );
        });
    }, [windowSize, visualMode, widthCount, heightCount]);

    return (
      <div className="relative z-20 top-[-200px] rotate-[-10deg] animate-rotater w-[100vw] h-[100vh]">
        {dots?.map((dot, i) => (
          <Point
            key={i}
            index={i}
            left={dot[0]}
            top={dot[1]}
            // text={`${i} ---  w${dot[0]} h${dot[1]}`}
          />
        ))}
      </div>
    );
  }
);

export default Canvas;
