import { memo, useEffect, useLayoutEffect, useState } from "react";

import Canvas from "./Canvas";

export type T_Dot = [number, number];
export type T_Frame = "diagonal" | "grid" | undefined;
export type T_Window = { width: number; height: number };

const Atmosphere = memo(
  ({
    startingLeft,
    startingTop,
  }: {
    startingLeft?: number;
    startingTop?: number;
  }) => {
    const [visualMode, setVisualMode] = useState<T_Frame>();
    const [windowSize, setWindowSize] = useState<T_Window>({
      width: window.outerWidth,
      height: window.outerHeight,
    });
    const [gridCount, setGridCount] = useState([10, 10]);
    const updateSize = () => {
      setWindowSize({
        width: window.outerWidth,
        height: window.outerHeight,
      });
    };

    useLayoutEffect(() => {
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);

    useEffect(() => {
      updateSize();
    }, []);

    const updateVisualMode = (mode: T_Frame) => {
      requestAnimationFrame(() => setVisualMode(mode));
    };

    useEffect(() => {
      setTimeout(() => {
        updateVisualMode("diagonal");
      }, 1000);
      setTimeout(() => {
        updateVisualMode("grid");
      }, 2000);
      setTimeout(() => {
        setGridCount([15, 15]);
      }, 3000);
      setTimeout(() => {
        setGridCount([20, 20]);
      }, 4000);
      return () => {
        updateVisualMode(undefined);
      };
    }, []);

    return (
      <span className="fixed z-10 top-0 left-0 w-full h-full overflow-hidden animate-gradienter">
        <Canvas
          widthCount={gridCount[0]}
          heightCount={gridCount[1]}
          startingLeft={startingLeft}
          startingTop={startingTop}
          windowSize={windowSize}
          visualMode={visualMode}
        />
      </span>
    );
  }
);

export default Atmosphere;
