import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { TContentItem } from "../../constants/types";

const PortfolioRoll = ({
  posts,
  basePath,
  className,
  showImages = false,
  showSlideshow = false,
}: {
  posts: TContentItem[] | undefined;
  basePath: string;
  className?: string;
  showImages?: boolean;
  showSlideshow?: boolean;
}) => {
  return (
    <div className={className}>
      <ul>
        {posts?.map((post) => (
          <li
            key={post.id}
            className="group relative cursor-pointer my-8 overflow-hidden bg-darkBlue bg-opacity-10 hover:bg-opacity-40 active:bg-blue transition-all shadow-lg h-[33vh]"
          >
            <Link to={`/${basePath}/${post.id}`} className="h-full block">
              <div
                className={
                  showImages && post?.thumbnailUrl
                    ? "relative z-20 w-1/3 p-2 backdrop-blur-md bg-darkBlue bg-opacity-50"
                    : "p-2 backdrop-blur-md bg-darkBlue bg-opacity-50"
                }
              >
                <h3 className="text-lg font-display">{post.title}</h3>
                <p className="font-thin text-secondaryText text-xs mt-3">
                  {sanitizeHtml(post.excerpt, {
                    allowedTags: ["b", "i", "em", "strong"],
                  })}
                </p>
              </div>
              {showImages && post?.thumbnailUrl && (
                <div className="-rotate-6 group-hover:rotate-0 group-hover:scale-75 group-hover:top-0 transition-all duration-1000 absolute top-0 left-40 z-10 w-4/5 overflow-hidden opacity-80 group-hover:opacity-100">
                  <img
                    src={post?.thumbnailUrl}
                    className="object-cover h-full w-full"
                  />
                </div>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PortfolioRoll;
