import { useDataContext } from "../contexts/DataContext";
import Post from "./Post";
// import posts from "../../data/posts.json";

const BlogPost = () => {
  const { blogPosts } = useDataContext();
  if (!blogPosts) return null;

  return <Post posts={blogPosts} />;
};

export default BlogPost;
