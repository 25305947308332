import { useDataContext } from "../contexts/DataContext";
import Post from "./Post";
// import posts from "../../data/posts.json";

const PortfolioPost = () => {
  const { portfolioItems } = useDataContext();

  if (!portfolioItems) return null;

  return <Post posts={portfolioItems} />;
};

export default PortfolioPost;
