import { Link } from "react-router-dom";

const BackButton = ({ path = "/" }: { path?: string }) => {
  return (
    <Link
      className="px-4 py-2 text-xl font-thin border-l border-transparent hover:pl-0 hover:pr-8 hover:border-green transition-all"
      to={path}
    >
      &lt;{" "}
    </Link>
  );
};

export default BackButton;
