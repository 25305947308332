import { Link, useParams } from "react-router-dom";

import sanitizeHtml from "sanitize-html";
import { TContentItem } from "../../constants/types";

const Blogroll = ({
  posts,
  basePath,
  className,
  showImages = false,
  showSlideshow = false,
}: {
  posts: TContentItem[] | undefined;
  basePath: string;
  className?: string;
  showImages?: boolean;
  showSlideshow?: boolean;
}) => {
  const { postId } = useParams();

  const selectedClass = "bg-blue bg-opacity-50";

  return (
    <div className={className}>
      <ul>
        {posts?.map((post) => (
          <li
            key={post.id}
            className={`${
              postId && parseFloat(postId) === post.id ? selectedClass : ""
            } group relative cursor-pointer my-8 bg-darkBlue bg-opacity-10 hover:bg-opacity-50 active:bg-blue p-4 transition-all shadow-lg`}
          >
            <Link to={`/${basePath}/${post.id}`}>
              <div
                className={
                  showImages && post?.thumbnailUrl
                    ? "relative z-20 w-2/3 p-2 backdrop-blur-md bg-grey bg-opacity-50"
                    : "p-2 backdrop-blur-md bg-grey bg-opacity-50"
                }
              >
                <h3 className="text-lg font-display">{post.title}</h3>
                <p className="font-thin text-secondaryText leading-5 text-sm mt-3">
                  {sanitizeHtml(post.excerpt, {
                    allowedTags: ["b", "i", "em", "strong"],
                  })}
                </p>
              </div>
              {showImages && post?.thumbnailUrl && (
                <div className="absolute -top-2 right-4 h-full w-1/2 right-0 z-10 overflow-hidden opacity-80 group-hover:opacity-100">
                  <img
                    src={post?.thumbnailUrl}
                    className="object-cover h-full w-full"
                  />
                </div>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Blogroll;
