import Typography from "../shared/Typography/Typography";

function Resume() {
  return (
    <div className="p-6 h-full overflow-auto">
      <Typography name="display1">Resume</Typography>
    </div>
  );
}

export default Resume;
