import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useMatches } from "react-router-dom";

import { navLinks } from "../constants/nav";
import NavSlider from "./NavSlider/NavSlider";

const Header = () => {
  const { state, pathname } = useLocation();
  const matches = useMatches();
  const isHome = pathname === "/";
  const isSecondLevel = matches.length === 3;

  const [hover, setHover] = useState(state?.hoverId || 0);

  const hoverBar = useRef(null);

  useEffect(() => {
    if (hoverBar.current) {
      const ele = hoverBar.current as HTMLDivElement;
      ele.style.top = hover === 0 ? "160px" : 160 + hover * 60 + "px";
    }
  }, [hover]);

  return (
    <div className="h-full relative font-display mt-[20vh] md:mt-0">
      <div className="pt-20 pb-0 pr-5 h-40">
        <Link to="/">
          <h1
            className={`${
              isHome ? "pl-20" : "pl-10"
            } transition-all w-full font-special text-[10vw] md:text-[2.2cqw] leading-none font-thin hover:text-blue transition`}
            onMouseOver={() => setHover(0)}
          >
            ERIC JAMES
          </h1>
          <strong className="pl-20 text-[6vw] md:text-[1.3cqw] font-thin tracking-wide text-blue">
            UI/UX DESIGNER
          </strong>
        </Link>
      </div>

      <div
        ref={hoverBar}
        className="bg-darkBlue w-full h-line absolute z-30 transition-all right-0 top-0"
      />

      <NavSlider setHover={setHover} hover={hover} links={navLinks} />
    </div>
  );
};

export default Header;
